<template>
    <ContentWrapper>
        <breadcrumb name="Harvests"/>
        <div class="container animated-fast fadeInUp">
            <b-row>
                <b-col sm="12" v-for="(item, index) in harvest.balance.groups"
                       :key="index">
                    <div class="card card-default bg-white border-0">
                        <div class="card-header">{{$t('growing.view.action.action.deliver_harvest.heading')}} #{{item.grow.label}}</div>
                        <div class="card-body">
                            <b-row class="align-items-center">
                                <b-col md="3" class="text-left border-right">
                                    <div class="text-lg">{{item.amount}}g</div>
                                    <p class="m-0">{{item.grow.strain_name}}</p>
                                </b-col>
                                <b-col md="9">
                                    <b>{{$t('growing.view.action.action.deliver_harvest.transactions.last')}}</b> <a class="ml-2" href="">{{$t('growing.view.action.action.deliver_harvest.transactions.show_all')}}</a>
                                    <hr/>
                                    <div class="row" v-for="transaction in item.transactions"
                                         :key="transaction.label">
                                        <div class="col-2">{{ transaction.label }}</div>
                                        <div class="col-4">{{ transaction.created_at | moment('utc', 'L')}}
                                            <span class="d-inline-block" style="width: 7em">{{ transaction.created_at | moment('utc', 'LTS')}}</span>
                                        </div>
                                        <div class="col-4">{{ $t('wallet.component.transactions.types.' + transaction.type + '.title') }}
                                        </div>
                                        <div class="text-right col">
                                            <span class="text-danger" v-if="!transaction.positive">-{{ transaction.amount | currency }}g</span>
                                            <span class="text-primary" v-if="transaction.positive">{{ transaction.amount | currency }}g</span>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                        <div class="card-footer bg-gray-dark bt0 clearfix btn-block d-flex">
                            <div class="ml-auto">
                                <b-btn :to="{name: 'Growing:Grow', params: {label: item.grow.label}}" variant="primary" href=""
                                       class="ml-2"><i class="fad fa-info-circle mr-1"></i>
                                    {{$t('growing.view.action.action.deliver_harvest.grow.details')}}
                                </b-btn>
                                <b-btn variant="primary" href="" class="ml-2"><i class="fad fa-sack-dollar mr-1"></i>
                                    {{$t('growing.view.action.action.deliver_harvest.grow.sell')}}
                                </b-btn>
                                <b-btn variant="primary" href="" class="ml-2"><i class="fad fa-truck-loading mr-1"></i>
                                    {{$t('growing.view.action.action.deliver_harvest.grow.deliver')}}
                                </b-btn>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>

            <div class="card card-default" v-if="harvest">
                <div class="card-header bg-gray-lighter text-bold">{{$t('growing.view.action.action.deliver_harvest.harvest.log.heading')}}</div>
                <div class="card-body">
                    <b-row class="grow-row" v-for="(item, index) in harvest.harvests" :key="index">
                        <b-col sm="2" class="grow-boxed">{{item.transaction.label}}</b-col>
                        <b-col sm="9" class="grow-caption">{{$t('growing.view.action.action.deliver_harvest.harvest.log.amount')}}{{Number(item.transaction.amount)}}g
                            {{item.grow.strain_name}} {{$t('growing.view.action.action.deliver_harvest.harvest.log.from')}} {{item.plants_amount}} {{$t('growing.view.action.action.deliver_harvest.harvest.log.of_plants')}}
                        </b-col>
                        <b-col sm="1">
                            <b-btn variant="primary"
                                   :to="{name: 'Growing:HarvestDistribution', params: {harvest_label: label}}">
                                <i class="fad fa-eye"></i>
                            </b-btn>
                        </b-col>
                    </b-row>
                </div>
            </div>

        </div>
    </ContentWrapper>
</template>
<style>

</style>
<script>

    export default {
        components: {},
        data() {
            return {
                harvest: null,
                loading: true
            }
        },
        mounted() {

            this.$api.get('growing/harvest').then(response => {
                this.harvest = response.data;
                this.loading = false;
            });
        },
        methods: {
            routeToHarvest(label = "REMOVE") {
                this.$router.push();
            },
            routeToGrow(label = "REMOVE") {
                this.$router.push({name: 'Grow', params: {label: label}});
            }
        }
    }
</script>
